<template>
  <b-modal
      id="modal-1"
      v-model="visibleModal"
      title="Usuwanie hurtowni"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="form-group text-center">
      <p>W celu potwierdzenia zmian przepisz poniższy kod:</p>
      <p><b>{{ code }}</b></p>
      <input v-model="form.inputCode" type="text" class="form-control" />
    </div>

    <div class="form-group text-center">
      <p class="text-danger">Powód usunięcia</p>
      <input v-model="form.reason" type="text" class="form-control" :class="{ 'is-invalid': $v.form.reason.$error }" />
      <div v-if="!$v.form.reason.required" class="invalid-feedback">{{ $t('message.required')}}</div>
    </div>

    <div class="text-right">
      <b-button @click="deleteWarehouse" variant="danger">Usuń hurtownie</b-button>
      <b-button class="ml-1" variant="secondary" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";
import {codeGenerator} from "@/helpers/code-generator";
import {required} from "vuelidate/lib/validators";

export default {
  name: "delete-warehouse-modal",

  data() {
    return {
      visibleModal: false,
      callback: null,
      warehouseId: "",
      code: "",
      form: {
        inputCode: "",
        reason: "",
      }
    }
  },

  validations: {
    form: {
      reason: { required }
    }
  },

  methods: {
    openModal(warehouseId, callback = null) {
      this.code = codeGenerator.generateRandomCode(6)
      this.warehouseId = warehouseId
      this.visibleModal = true
      this.callback = callback
    },

    hideModal() {
      this.visibleModal = false
      this.form.inputCode = ""
      this.form.reason = ""
    },

    async deleteWarehouse() {
      if (this.code !== this.form.inputCode) {
        await Swal.fire("Błąd!", "Kod przepisany nie zgadza się!", "error");
        return
      }

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      axios.delete(`/warehouse`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          id: this.warehouseId,
          reason: this.form.reason
        }
      }).then(() => {
        this.hideModal()
        Swal.fire("Sukces!", "Hurtownia usunięta pomyślnie", "success")

        if (this.callback) {
          this.callback()
        }
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      });
    }
  }

}
</script>