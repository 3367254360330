<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {email, maxLength, required} from "vuelidate/lib/validators";
import allCountries from "@/helpers/all-countries";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {paginationHelper} from "@/helpers/pagination-helper";
import Swal from "sweetalert2";
import DeleteWarehouseModal from "@/components/warehouse/delete-warehouse-modal.vue";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

export default {
  components: {
    CustomCardSubtitle,
    Layout,
    PageHeader,
    DeleteWarehouseModal
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: ""
      },

      createModal: false,
      editWarehouseModal: false,
      warehouses: null,

      limits: {
        warehouseName: 255,
        companyName: 255,
        companyAddress: 255,
        website: 255,
        extraContent: 1000
      },

      form: {
        companyName: "",
        warehouseName: "",
        industryId: "",
        companyAddress: "",
        taxNumber: "",
        contactPhoneDialCode: "+48",
        contactPhoneNumber: "",
        contactEmail: "",
        website: "",
        extraContent: "",
        referralCode: "",
        marketId: ""
      },

      carriers: [
        {
          carrierId: "",
          allegroCarrierId: "",
          shippingCost: 0
        }
      ],

      warehouse: {},

      filtering: {
        options: [
          "1",
          "2",
          "3"
        ],

        option: "1",
      },

      warehousesFromForms: []
    };
  },

  validations() {
    return {
      form: {
        companyName: {required, maxLength: maxLength(this.limits.companyName)},
        warehouseName: {required, maxLength: maxLength(this.limits.warehouseName)},
        industryId: {required},
        companyAddress: {required, maxLength: maxLength(this.limits.companyAddress)},
        taxNumber: {required/*, minLength: minLength(this.limits.taxNumber), maxLength: maxLength(this.limits.taxNumber)*/},
        contactPhoneDialCode: {required},
        contactPhoneNumber: {required},
        contactEmail: {required, email},
        website: {required, maxLength: maxLength(this.limits.website)},
        extraContent: {required, maxLength: maxLength(this.limits.extraContent)},
        marketId: {required}
      },

      warehouse: {
        name: {required, maxLength: maxLength(255)}
      }
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('wholesaler-management.title'),
          active: true
        }
      ]
    },

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getOption(value) {
      if (value === "1") {
        return "Wszystkie"
      } else if (value === "2") {
        return "Aktywne"
      } else if (value === "3") {
        return "Oczekujące"
      }

      return "Brak"
    },

    getAllCountries() {
      return allCountries;
    },

    registerCompany() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      if (this.carriers) {
        const hasPermission = this.$store.getters['role/hasPermission']('WAREHOUSE_ALLEGRO_CARRIER')

        for (const carrier of this.carriers) {
          if (!carrier.carrierId) {
            return;
          }

          if (!carrier.allegroCarrierId && hasPermission) {
            return
          }
        }
      }

      if (!this.carriers || this.carriers.length === 0) {
        Swal.fire("Błąd!", "Brak przewoźników!", "error")
        return
      }

      const json = JSON.stringify({
        form: {
          ...this.form,
          carriers: JSON.stringify(this.carriers)
        }
      });

      axios.post(`/wholesaler/registration`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t('wholesaler-management.register.success'), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.hideCreateModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    getFields() {
      if (this.filtering.option === "1") {
        return [
          {key: "name", label: this.$t('message.name')},
          {key: "status", label: 'Status'},
          {
            key: "ordersNumber", label: this.$t('wholesaler-management.table.order-numbers'), formatter: value => {
              if (!value) {
                return "-"
              }

              return value
            }
          },
          {key: "action", label: this.$t('table.actions')}
        ]
      } else if (this.filtering.option === "3") {
        return [
          {key: "companyName", label: this.$t('message.name')},
          {key: "status", label: 'Status'},
        ]
      }

      return [
        {key: "name", label: this.$t('message.name')},
        {key: "ordersNumber", label: this.$t('wholesaler-management.table.order-numbers')},
        {key: "action", label: this.$t('table.actions')}
      ]
    },

    async loadWarehouses() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        let url = "/warehouse"
        if (this.filtering.option === "1") {
          url = "/warehouse-or-form/pagination"
        } else if (this.filtering.option === "3") {
          url = "/wholesaler/registration/list-all-pending"
        }

        const {data} = await axios.get(`${url}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            "page": page,
            "size": this.table.perPage
          }
        });

        if (this.filtering.option === "1") {
          this.warehouses = data.warehouseOrForms
          this.table.rows = data.count;
          this.table.totalRows = data.count;
        } else {
          this.warehouses = data
          this.table.rows = this.warehouses.length;
          this.table.totalRows = this.warehouses.length;
        }

        await this.loadWarehousesFromForms()
        return this.warehouses;
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadWarehousesFromForms() {
      try {
        const ids = [];
        for (let warehouseOrForm of this.warehouses) {
          if (warehouseOrForm.status === "ACCEPTED") {
            if (warehouseOrForm.warehouseId) {
              ids.push(warehouseOrForm.warehouseId);
            }
          }
        }

        if (ids.length === 0) {
          return
        }

        const json = JSON.stringify({
          ids: ids
        })

        const {data} = await axios.post(`/warehouse/fetch`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.warehousesFromForms = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getWarehouseFromForm(id) {
      if (!this.warehousesFromForms) {
        return null
      }

      return this.warehousesFromForms.get(id) || null
    },

    showWarehouseModal(warehouse) {
      let editingWarehouse = warehouse
      if (warehouse.status === "ACCEPTED" && warehouse.warehouseId) {
        editingWarehouse = this.getWarehouseFromForm(warehouse.warehouseId)
      }

      this.warehouse = Object.assign({}, editingWarehouse);
      this.editWarehouseModal = true;
    },

    getStatusText(status) {
      if (status === 'ACCEPTED') {
        return "registration-forms.status.accepted"
      } else if (status === 'DECLINED') {
        return "registration-forms.status.declined"
      }

      return "registration-forms.status.waiting"
    },

    // eslint-disable-next-line no-unused-vars
    hideCreateModal(e) {
      this.submitted = false;
      this.createModal = false;
      this.form = {};
      this.carriers = [
        {
          carrierId: "",
          allegroCarrierId: "",
          shippingCost: 0
        }
      ]
    },

    editWarehouse() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.warehouse.$touch();
      if (this.$v.warehouse.$invalid) {
        return;
      }

      let idObject = {}
      if (this.warehouse.status === "ACCEPTED") {
        idObject = {
          id: this.warehouse.warehouseId
        }
      }

      const json = JSON.stringify({
        ...this.warehouse,
        ...idObject
      })

      axios.post(`/warehouse`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t('wholesaler-management.warehouse.edit.success'), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh()
        this.hideWarehouseModal()
      }).catch((error) => {
        this.submitted = false;
        errorCatcher.catchErrors(error)
      })
    },

    // eslint-disable-next-line no-unused-vars
    hideWarehouseModal(e) {
      this.submitted = false;
      this.editWarehouseModal = false;
      this.warehouse = {};
    },

    addNewCarrier() {
      this.carriers.push({
        carrierId: "",
        allegroCarrierId: "",
        shippingCost: 0
      })
    },

    deleteCarrier(warehouseCarrier) {
      const index = this.carriers.findIndex((element) => {
        return element === warehouseCarrier;
      });

      if (index !== -1) {
        this.carriers.splice(index, 1);
      }
    }

  },

  computed: {

    paginationHelper() {
      return paginationHelper
    }

  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('wholesaler-management.title')"/>
    <delete-warehouse-modal ref="deleteWarehouseModal"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row py-2">
              <custom-card-subtitle title="Filtrowanie"/>
              <div class="col-12 col-lg-2">
                <label>Hurtownia:</label>
                <vue-multiselect
                    v-model="filtering.option"
                    @select="setToFirstPageAndRefresh"
                    placeholder="Wybierz typ filtracji"
                    :options="filtering.options"
                    :custom-label="value => getOption(value)"
                    :show-labels="false"
                    :allow-empty="false"
                />
              </div>
            </div>

            <div class="row py-2">
              <custom-card-subtitle title="Akcje"/>
              <div class="col-12 col-lg-4">
                <div class="button-items mb-2">
                  <a class="btn btn-success mb-2 px-5" @click="createModal = true">
                    <i class="mdi mdi-plus mr-2"></i> {{ $t('wholesaler-management.create') }}
                  </a>
                </div>
              </div>

            </div>

            <div class="py-2">
              <div class="py-2">
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.show') }}&nbsp;
                        <b-form-select v-model="table.perPage" size="sm"
                                       :options="table.pageOptions" @loaded="setToFirstPageAndRefresh"></b-form-select>&nbsp;{{
                          $t('table.entries')
                        }}
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.search') }}
                        <b-form-input v-model="table.filter" class="form-control form-control-sm ml-2" type="search"
                                      @keyup.enter="setToFirstPageAndRefresh"
                                      @input="setToFirstPageAndRefresh"></b-form-input>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-lg-12 col-md-12 d-inline-flex justify-content-end">
                    <div class="dataTables_paginate paging_simple_numbers">
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"/>
                      </ul>
                    </div>
                    <div class="align-items-center">
                      <label class="d-inline-flex align-items-center mx-2">
                        <b-form-input v-model="table.inputPage" class="form-control form-control-sm ml-2 form-xs"/>
                      </label>
                      <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">Idź do
                        strony
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <b-table
                    ref="table"

                    :current-page="table.currentPage"
                    :fields="getFields()"
                    :filter-included-fields="table.filterOn"
                    :items="loadWarehouses"
                    :per-page="table.perPage"
                    :sort-by.sync="table.sortBy"
                    :sort-desc.sync="table.sortDesc"
                    responsive="sm"
                    :empty-text="$t('message.no-elements')"
                    :empty-filtered-text="$t('message.no-records')" :show-empty="true"
                    @filtered="elements => paginationHelper.onFiltered(table, elements)"
                    :busy.sync="table.isBusy">
                  <div slot="table-busy" class="text-center">
                    <h5>{{ $t('message.loading') }}</h5>
                    <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
                  </div>

                  <template v-slot:cell(action)="{ item }">
                    <template v-if="!item.status || item.status === 'ACCEPTED'">
                      <a @click="showWarehouseModal(item)" class="clickable-element mr-3 text-primary"><i
                          class="mdi mdi-pencil font-size-18"></i></a>
                    </template>

                    <template v-if="!item.status">
                      <a :id="`delete-warehouse-${item.id}`"
                         @click="$refs.deleteWarehouseModal.openModal(item.id, () => $refs.table.refresh())"
                         class="clickable-element mr-2 text-danger">
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>

                      <b-tooltip :target="`delete-warehouse-${item.id}`" placement="left">Usuń hurtownie</b-tooltip>
                    </template>
                  </template>

                  <template v-slot:cell(status)="{ item }">
                        <span v-if="item.status" class="font-size-11 badge"
                              :class="{ 'badge-soft-success': item.status === 'ACCEPTED',
                               'badge-soft-danger': item.status === 'DECLINED',
                               'badge-soft-warning': item.status === 'AWAITING_CONSIDERATION' }"> {{
                            $t(getStatusText(item.status))
                          }}
                        </span>
                    <span v-else>-</span>
                  </template>

                </b-table>
              </div>

              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="table.currentPage" :per-page="table.perPage"
                                    :total-rows="table.rows"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        size="xl"
        v-model="createModal"
        v-if="createModal"
        :scrollable="true"
        :title="$t('wholesaler-management.register.title')"
        hide-footer
        title-class="font-18">
      <form>

        <div class="form-group">
          <label for="referral-code">Kod polecającego</label>
          <input id="referral-code" v-model="form.referralCode" class="form-control" type="text"/>
        </div>

        <div class="form-group">
          <ecat-multiselect @change="value => this.form.industryId = value"
                            save-id="id"
                            view-id="name"
                            :label="$t('message.industry')"
                            placeholder="Wybierz branże"
                            load-url="/industry/list/pagination"
                            query-url="/industry/by-name"
                            list-name="industries"
                            param="name"
                            :class="{ 'is-invalid': submitted && $v.form.industryId.$error }"
          />

          <div v-if="submitted && !$v.form.industryId.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
        </div>

        <div class="form-group">
          <ecat-multiselect
              @change="value => this.form.marketId = value"
              save-id="id"
              :custom-label="value => $t(value.i18nTranslation)"
              label="Rynek"
              placeholder="Wybierz rynek"
              load-url="/market/pagination"
              list-name="markets"
              :class="{ 'is-invalid': submitted && $v.form.marketId.$error }"
          />

          <div v-if="submitted && !$v.form.marketId.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
        </div>

        <div class="form-group">
          <label for="company-name">{{ $t('business.company-name') }}</label>
          <input id="company-name" v-model="form.companyName"
                 :class="{ 'is-invalid': submitted && $v.form.companyName.$error }" class="form-control"
                 :maxlength="this.limits.companyName" type="text"/>
          <p v-if="form.companyName" class="badge position-absolute"
             :class="{ 'badge-success': form.companyName.length !== this.limits.companyName, 'badge-danger': form.companyName.length === this.limits.companyName }">
            {{ form.companyName.length }} /
            {{ limits.companyName }}
          </p>

          <div v-if="submitted && !$v.form.companyName.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
          <div v-if="submitted && !$v.form.companyName.maxLength" class="invalid-feedback">
            {{ $t('wholesaler-management.register.company-name-too-long') }}
          </div>
        </div>

        <div class="form-group">
          <label for="visible-name">Wyświetlana nazwa</label>
          <input id="visible-name" v-model="form.warehouseName"
                 :class="{ 'is-invalid': submitted && $v.form.warehouseName.$error }" class="form-control"
                 :maxlength="this.limits.warehouseName" type="text"/>
          <p v-if="form.warehouseName" class="badge position-absolute"
             :class="{ 'badge-success': form.warehouseName.length !== this.limits.warehouseName, 'badge-danger': form.warehouseName.length === this.limits.warehouseName }">
            {{ form.warehouseName.length }} /
            {{ limits.warehouseName }}
          </p>

          <div v-if="submitted && !$v.form.warehouseName.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
          <div v-if="submitted && !$v.form.warehouseName.maxLength" class="invalid-feedback">
            {{ $t('wholesaler-management.register.company-name-too-long') }}
          </div>
        </div>

        <div class="form-group">
          <label for="company-address">{{ $t('wholesaler-management.register.company-info.address') }}</label>
          <input id="company-address" v-model="form.companyAddress"
                 :class="{ 'is-invalid': submitted && $v.form.companyAddress.$error }"
                 :maxlength="this.limits.companyAddress" class="form-control" type="text"/>
          <p v-if="form.companyAddress" class="badge position-absolute"
             :class="{ 'badge-success': form.companyAddress.length !== this.limits.companyAddress, 'badge-danger': form.companyAddress.length === this.limits.companyAddress }">
            {{ form.companyAddress.length }} /
            {{ limits.companyAddress }}
          </p>

          <div v-if="submitted && !$v.form.companyAddress.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
          <div v-if="submitted && !$v.form.companyAddress.maxLength" class="invalid-feedback">
            {{ $t('wholesaler-management.register.company-address-too-long') }}
          </div>
        </div>

        <div class="form-group">
          <label for="tax-number">{{ $t('business.tax') }}</label>
          <input id="tax-number" v-model="form.taxNumber"
                 :class="{ 'is-invalid': submitted && $v.form.taxNumber.$error }" :maxlength="this.limits.taxNumber"
                 class="form-control" type="text"/>
<!--          <p v-if="form.taxNumber" class="badge position-absolute"-->
<!--             :class="{ 'badge-success': form.taxNumber.length !== this.limits.taxNumber, 'badge-danger': form.taxNumber.length === this.limits.taxNumber }">-->
<!--            {{ form.taxNumber.length }} /-->
<!--            {{ limits.taxNumber }}-->
<!--          </p>-->

          <div v-if="submitted && !$v.form.taxNumber.required" class="invalid-feedback">{{ $t('message.required') }}
          </div>
<!--          <div v-if="submitted && !$v.form.taxNumber.minLength" class="invalid-feedback">-->
<!--            {{ $t('wholesaler-management.register.tax-number-min-length') }}-->
<!--          </div>-->
<!--          <div v-if="submitted && !$v.form.taxNumber.maxLength" class="invalid-feedback">-->
<!--            {{ $t('wholesaler-management.register.tax-number-min-length') }}-->
<!--          </div>-->
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label id="phone-country">{{ $t('message.country') }}</label>
              <select id="phone-country" v-model="form.contactPhoneDialCode"
                      :class="{ 'is-invalid': submitted && $v.form.contactPhoneDialCode.$error }"
                      class="custom-select">
                <option v-for="(item, index) in getAllCountries()" :key="index" :value="`${item.dialCode}`">
                  {{ item.name }} ({{ item.dialCode }})
                </option>
              </select>

              <div v-if="submitted && $v.form.contactPhoneDialCode.$error" class="invalid-feedback">
                <span v-if="!$v.form.contactPhoneDialCode.required">{{ $t('message.required') }}</span>
              </div>
            </div>

            <div class="col-6 mb-2">
              <label id="phone-number">{{ $t('message.phone-number') }}</label>
              <input
                  id="phone-number"
                  v-model="form.contactPhoneNumber"
                  :class="{ 'is-invalid': submitted && $v.form.contactPhoneNumber.$error }"
                  class="form-control"
                  type="text"/>
              <div v-if="submitted && $v.form.contactPhoneNumber.$error" class="invalid-feedback">
                <span v-if="!$v.form.contactPhoneNumber.required">{{ $t('message.required') }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="email">{{ $t('wholesaler-management.register.company-info.email') }}</label>
          <input id="email" v-model="form.contactEmail"
                 :class="{ 'is-invalid': submitted && $v.form.contactEmail.$error }" class="form-control"
                 type="text"/>
          <div v-if="submitted && !$v.form.contactEmail.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
          <div v-if="submitted && !$v.form.contactEmail.email" class="invalid-feedback">
            {{ $t('message.email-error') }}
          </div>
        </div>

        <div class="form-group">
          <label for="website">{{ $t('wholesaler-management.register.company-info.website') }}</label>
          <input id="website" v-model="form.website" :class="{ 'is-invalid': submitted && $v.form.website.$error }"
                 :maxlength="this.limits.website" class="form-control" type="text"/>
          <p v-if="form.website" class="badge position-absolute"
             :class="{ 'badge-success': form.website.length !== this.limits.website, 'badge-danger': form.website.length === this.limits.website }">
            {{ form.website.length }} /
            {{ this.limits.website }}
          </p>

          <div v-if="submitted && !$v.form.website.required" class="invalid-feedback">{{ $t('message.required') }}</div>
          <div v-if="submitted && !$v.form.website.maxLength" class="invalid-feedback">
            {{ $t('wholesaler-management.register.website-too-long') }}
          </div>
        </div>

        <div class="form-group">
          <label>{{ $t('wholesaler-management.register.details.specific-questions') }}</label>

          <label>{{ $t('wholesaler-management.register.details.xml-frequency') }}</label>
          <label>{{ $t('wholesaler-management.register.details.right-to-use-images') }}</label>
          <label>{{ $t('wholesaler-management.register.details.orders-through-website') }}</label>
          <label>{{ $t('wholesaler-management.register.details.form-of-communication') }}</label>
          <label>{{ $t('wholesaler-management.register.details.can-buy-one-piece') }}</label>
          <br>
          <label>{{ $t('wholesaler-management.register.details.transport-cost') }}</label>
          <label>{{ $t('wholesaler-management.register.details.shipment-from-directly') }}</label>
          <label>{{ $t('wholesaler-management.register.details.price-policy') }}</label>
          <label>{{ $t('wholesaler-management.register.details.complaints') }}</label>
          <label>{{ $t('wholesaler-management.register.details.refunds') }}</label>
          <label>{{ $t('wholesaler-management.register.details.paid-integration') }}</label>
          <label>{{ $t('wholesaler-management.register.details.image-watermark') }}</label>
          <label>{{ $t('wholesaler-management.register.details.shipping-date') }}</label>
          <label>{{ $t('wholesaler-management.register.details.xml-gab-information') }}</label>

          <label for="extraContent">{{ $t('wholesaler-management.register.details.reply') }}</label>
          <textarea id="extraContent" v-model="form.extraContent"
                    :class="{ 'is-invalid': submitted && $v.form.extraContent.$error }"
                    :maxlength="this.limits.extraContent" class="form-control" type="text"/>
          <p v-if="form.extraContent" class="badge position-absolute"
             :class="{ 'badge-success': form.extraContent.length !== this.limits.extraContent, 'badge-danger': form.extraContent.length === this.limits.extraContent }">
            {{ form.extraContent.length }} /
            {{ this.limits.extraContent }}
          </p>

          <div v-if="submitted && !$v.form.extraContent.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
          <div v-if="submitted && !$v.form.extraContent.maxLength" class="invalid-feedback">
            {{ $t('wholesaler-management.register.extra-content-too-long') }}
          </div>
        </div>

        <div class="form-group">
          <h5 class="text-center">Ceny dostaw hurtowni</h5>

          <div v-for="(warehouseCarrier, index) in carriers" :key="index" class="mb-2">
            <div class="row">
              <div class="col-2" v-if="!$store.getters['role/hasPermission']('WAREHOUSE_ALLEGRO_CARRIER')"/>

              <div class="col-4">
                <ecat-multiselect
                    placeholder="Wybierz przewoźnika"
                    @change="value => warehouseCarrier.carrierId = value"
                    load-url="/carrier/list/pagination"
                    query-url="/carrier/by-name"
                    save-id="id"
                    view-id="name"
                    param="name"
                    :class="{ 'is-invalid': submitted && !warehouseCarrier.carrierId }"
                />
                <div v-if="submitted && !warehouseCarrier.carrierId" class="invalid-feedback">
                  {{ $t('message.required') }}
                </div>
              </div>

              <div class="col-4" v-if="$store.getters['role/hasPermission']('WAREHOUSE_ALLEGRO_CARRIER')">
                <ecat-multiselect
                    placeholder="Wybierz przewoźnika Allegro"
                    @change="value => warehouseCarrier.allegroCarrierId = value"
                    load-url="/allegro/carrier/list/pagination"
                    query-url="/allegro/carrier/list/by-name"
                    save-id="id"
                    view-id="name"
                    param="name"
                    :class="{ 'is-invalid': submitted && !warehouseCarrier.allegroCarrierId }"
                />
                <div v-if="submitted && !warehouseCarrier.allegroCarrierId" class="invalid-feedback">
                  {{ $t('message.required') }}
                </div>
              </div>

              <div class="col-3">
                <input v-model.number="warehouseCarrier.shippingCost" type="number" class="form-control"/>
              </div>

              <div class="col-1 py-1">
                <b-button variant="danger" class="btn-sm" @click="deleteCarrier(warehouseCarrier)">Usuń</b-button>
              </div>
            </div>
          </div>

          <div class="text-center py-3">
            <b-button variant="success" @click="addNewCarrier">Dodaj nową opcję</b-button>
          </div>
        </div>

        <div class="text-center mb-2">
          <b>{{ $t('wholesaler-management.register.info') }}</b>
        </div>

        <div class="text-center">
          <b-button variant="success" @click="registerCompany">{{
              $t('wholesaler-management.register.button')
            }}
          </b-button>
          <b-button class="ml-1" variant="danger" @click="hideCreateModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

    <!-- Modal -->
    <b-modal
        id="modal-2"
        v-model="editWarehouseModal"
        v-if="editWarehouseModal"
        :scrollable="true"
        :title="$t('wholesaler-management.warehouse.title')"
        hide-footer
        title-class="font-18"
        @hide="hideWarehouseModal"
        @esc="hideWarehouseModal">
      <form>

        <div class="form-group">
          <label for="name">{{ $t('message.name') }}</label>
          <input id="name" v-model="warehouse.name" :class="{ 'is-invalid': submitted && $v.warehouse.name.$error }"
                 class="form-control" type="text"/>
          <div v-if="submitted && !$v.warehouse.name.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
          <div v-if="submitted && !$v.warehouse.name.maxLength" class="invalid-feedback">
            {{ $t('wholesaler-management.register.company-name-too-long') }}
          </div>
        </div>

        <div class="text-right">
          <b-button variant="success" @click="editWarehouse">{{ $t('wholesaler-management.warehouse.edit.button') }}
          </b-button>
          <b-button class="ml-1" variant="danger" @click="hideWarehouseModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>